import React from "react"
import * as Ui from "components/ui"
import * as Sections from 'components/sections'
import * as Layout from 'components/layout'
import Content_FeatureTrust from "components/content/feature_trust-backups.js";
import Content_FeatureControl from "components/content/feature_control-backups.js";

const ContentPage = () => {
  const keyRef = 'hetzner';
  const provider = 'Hetzner';

  return (
  <Layout.Layout textColor="dark">
    <Layout.SEO
      refKey="snapshots"
      title="Automated Hetzner Backups and Snapshots"
      description="Automatically back up your all your Hetzner Instances. Set a schedule and let it work in peace."
    />
    <Layout.Hero
      type="primary"
      title={(<>Automated <span className="text-primary">Hetzner instance</span> backups</>)}
      subTitle={(
        <>Automatically back up your all your Hetzner Instances.<br/>Recover your data in minutes, and focus on building incredible things.
        </>)}
      icons={[
        <p className="mb-2 font-light text-xs tracking-wider uppercase opacity-75">Fully integrated with:</p>,
        <Ui.Image filename="brands/icons/hetzner-ico.png" alt="Hetzner backup" className="w-6"/>,
      ]}
      cta={(<Ui.SignupButton className="mt-6" noSubtitle text="Automate your Hetzner Backup" urlAtrr={{ sb_source: "website", sb_term: "hetzner-hero" }} />)}
      illustration={(
        <Ui.Image className="" filename={`simplebackups-${keyRef}-snapshot-hero.png`} alt={`SimpleBackups ${provider} snapshot`}/>
      )}
      withIllustrationBox={false}
    />

    <Ui.Container>
      <Sections.JoinBrandsSection />
    </Ui.Container>

    <Ui.Section>
      <Ui.Container>
        <Ui.Row>
          <Ui.FeatureRows items={
            [
              {
                title: (<>Connect Your Hetzner Account</>),
                description: (
                  <>
                    <p>
                      Easily connect your Hetzner account(s) using Hetzner API Token.<br/>
                      Select the Instances you want to backup and you're done.
                      <Ui.FeatureChecks items={['Server/Instance Backup', 'Unlimited connected accounts']} />
                      <Ui.Link arrow="right" to="#features">View all features</Ui.Link>
                    </p>
                  </>
                ),
                image: ( <Ui.Image
                  className="shadow-2xl rounded-xl"
                  filename={`simplebackups_hetzner_connect.png`}
                  alt={`${provider} backup `}
                  style={{maxWidth: 450}}
                />)
              },
              {
                preTitle: "Hetzner Instance snapshot",
                title: (<>Flexible scheduling for Hetzner backups</>),
                description: (
                  <>
                    <p>
                      Weekly Hetzner backups just don’t cut it.<br/>
                      Unlock flexible scheduling & retention for your Hetzner backups.<br/>

                      <Ui.FeatureChecks items={[
                        'Control your backup schedule', 'Unrestricted retention', 'Unlimited backup intervals']} />
                      <Ui.Link arrow="right" to="#features">View all features</Ui.Link>
                    </p>
                  </>
                ),
                image: ( <Ui.Image
                  className="shadow-2xl rounded-xl"
                  filename='simplebackups-app-scheduling'
                  alt={`${provider} Backup custom scheduling`}
                  style={{maxWidth: 450}}
                />)
              },

              Content_FeatureTrust(),
            ]}></Ui.FeatureRows>

        </Ui.Row>
      </Ui.Container>
    </Ui.Section>

    <Ui.Section color="alt" id="features">
      <Ui.Container>
        <Ui.Row>
          <Ui.Typography tag="h2" className="text-center">Full features Backup as a Service</Ui.Typography>
          <div className="text-center">
            <p className="mb-6">From setting up your backups, to notifications, anomaly detection and security access, you're covered.</p>
            <Sections.FeatureSlider tags={["snapshot"]} />
          </div>
          <p className="flex justify-center pt-6"><Ui.Link arrow="right" to="#features">View all features</Ui.Link></p>
        </Ui.Row>
      </Ui.Container>
    </Ui.Section>

    <Sections.SectionLearn
      title={<>Wanna learn more about Hetzner Server Backups?</>}
      text="Find out more and get started with our Hetzner  Backup guides"
      tags={['snapshot', 'hetzner-snapshot']}
    />

    <Sections.SectionTestimonialExtended />
    <Sections.SectionGetStarted/>
    <Sections.SectionFaq color="alt" faqs={['hetzner_what_is_snapshot', 'hetzner_where_are_snapshots_stored', 'how_snapshot']}/>

  </Layout.Layout>
)}

export default ContentPage
